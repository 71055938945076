import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasClassicTheme } from 'common/src/theme/saasClassic';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/SaasClassic/saasClassic.style';
import '@redq/reuse-modal/es/index.css';

// ELEMENTS
// import Box from 'reusecore/src/elements/Box';
// import Button from 'reusecore/src/elements/Button';
// import Card from 'reusecore/src/elements/Card';
// import Heading from 'reusecore/src/elements/Heading';
// import Container from 'common/src/components/UI/Container';
// import Input from 'reusecore/src/elements/Input';
// import Image from 'reusecore/src/elements/Image';
// import Text from 'reusecore/src/elements/Text';

// COMPONENTS
import SEO from '../../components/seo';
import PodcastSearch from 'common/src/components/PodcastSearch';

// CONTAINERS
// import BannerSection from '../containers/SaasClassic/Banner';
import Navbar from '../../containers/SaasClassic/Navbar';
// import Newsletter from '../containers/SaasClassic/Newsletter';
// import Footer from '../containers/SaasClassic/Footer';

// HANDLERS
import { redirectToPage } from '../../../../api/BrowserAPI'

const PodcastSearchPage = () => {
  return (
    <ThemeProvider theme={saasClassicTheme}>
      <Fragment>
        <SEO title="podstacks | ADMIN - Search Podcasts" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>

          <PodcastSearch
            title="Search Podcasts"
            tileClickCB={ podcast =>
              redirectToPage( 'admin/searchEpisodes', { jobId: podcast.id })
            }
          />

          {/* <Footer /> */}
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default PodcastSearchPage;
